import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBox from 'components/Advanced_User/OpenHAB_IP_Camera_Binding/PrimaryBox';
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Mosquitto Setup under openHABian",
  "path": "/Advanced_User/openHABian_on_Raspberry_Pi/Mosquitto_Setup/",
  "dateChanged": "2018-09-28",
  "author": "Mike Polinowski",
  "excerpt": "The open Home Automation Bus (openHAB) is an open source, technology agnostic home automation platform which runs as the center of your smart home. openHABian aims to provide a self-configuring Linux system setup specific to the needs of every Raspberry Pi user. Control your INSTAR IP camera with your home automation system. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD",
  "image": "./AU_SearchThumb_openHABian.png",
  "social": "/images/Search/AU_SearchThumb_openHABian.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_OpenHAB2_white.webp",
  "chapter": "Advanced User",
  "category": "smarthome",
  "type": "OpenHAB"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='Mosquitto Setup under openHABian' dateChanged='2018-09-28' author='Mike Polinowski' tag='INSTAR IP Camera' description='The open Home Automation Bus (openHAB) is an open source, technology agnostic home automation platform which runs as the center of your smart home. openHAB software integrates different home automation systems, devices and technologies into a single solution. It provides uniform user interfaces, and a common approach to automation rules across the entire system, regardless of the number of manufacturers and sub-systems involved. Control your INSTAR IP camera with your home automation system. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD' image='/images/Search/AU_SearchThumb_openHABian.png' twitter='/images/Search/AU_SearchThumb_openHABian.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/openHABian_auf_Raspberry_Pi/Mosquitto_Setup/' locationFR='/fr/Advanced_User/openHABian_on_Raspberry_Pi/Mosquitto_Setup/' crumbLabel="OpenHAB" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "mosquitto-setup-unter-openhabian",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#mosquitto-setup-unter-openhabian",
        "aria-label": "mosquitto setup unter openhabian permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Mosquitto Setup unter openHABian`}</h1>
    {/* TOC */}
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#openhab-cli-tool"
        }}>{`OpenHAB CLI Tool`}</a>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#troubleshooting"
            }}>{`Troubleshooting`}</a></li>
        </ul>
      </li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#manuelle-installation-optional"
        }}>{`Manuelle Installation (optional)`}</a></li>
    </ul>
    {/* /TOC */}
    <EuiSpacer mdxType="EuiSpacer" />
    <PrimaryBox mdxType="PrimaryBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h2 {...{
      "id": "openhab-cli-tool",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#openhab-cli-tool",
        "aria-label": "openhab cli tool permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`OpenHAB CLI Tool`}</h2>
    <p>{`Just as Node-RED, Mosquitto (our `}<a parentName="p" {...{
        "href": "/en/Advanced_User/OpenHAB_Home_Automation/Mosquitto_Installation_on_Windows/"
      }}>{`Broker for the MQTT Protocol`}</a>{`) can be installed through the OpenHAB-CLI. First, login to your Pi via SSH (Windows user can use the free Terminal from `}<a parentName="p" {...{
        "href": "https://mobaxterm.mobatek.net/download.html"
      }}>{`Mobaterm`}</a>{` and open the CLI tool with `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`sudo openhabian-config`}</code>{`. Use your user password (on openHABian this is `}<strong parentName="p">{`openhabian`}</strong>{` by default) to execute the program, choose `}<em parentName="p">{`Optional Components`}</em>{` and select Mosquitto:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7a6053da9f6ad25ae34e466566ee6b96/e9beb/OpenHabian_CLI_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "33.04347826086957%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABlElEQVQY003IXU/aUACA4f5Qr9H6G5ZdAONjk8hnCwi2VcDyoYIRJ1I5p6WNaGmh52CLSgSByiommyYmy64Wt10seW7el2iqJw290TCaIgLAOJdNUUZQxKCNoTSAEANotMRr2LZEyQAAt4AFAQIyEmIKJF5wfqnnp2z4NhEcxT4/JjbtVNiKBu6pjXEyNEqGxnRoEvnySIWdVPSNS/8EhddR9ZfFM/sp4puxN+kW8DGt5EPDk8yDwI3Oti/4sLYf7R0melWqX6O7pVj/gBrUUuPmjtOtLG+PXs1yurJFLBE/1Qtai1YFao5LjlW5U3el40inEVcFunMaV+pR5WtMqkeuBHqKeBsV5/3id7yXrqSJJS7N+sWhwmKYtRR2bpRmvaIps2absWT25mJnIDFY3EZi1kbl52H1eVhzzOqLWc4cZgmnk5ldMvYVu1C5RZebKJlZh3nSc3852u6TnntQsvYl9yffz0LL/dDYLZ4iXO4PLr/b5XOv+tyk37Me9P6PDHrJgIcMeN/9O5614CfS+3ElEv8NY1wotWy6Bo4AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7a6053da9f6ad25ae34e466566ee6b96/e4706/OpenHabian_CLI_05.avif 230w", "/en/static/7a6053da9f6ad25ae34e466566ee6b96/d1af7/OpenHabian_CLI_05.avif 460w", "/en/static/7a6053da9f6ad25ae34e466566ee6b96/b6582/OpenHabian_CLI_05.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7a6053da9f6ad25ae34e466566ee6b96/a0b58/OpenHabian_CLI_05.webp 230w", "/en/static/7a6053da9f6ad25ae34e466566ee6b96/bc10c/OpenHabian_CLI_05.webp 460w", "/en/static/7a6053da9f6ad25ae34e466566ee6b96/87ca7/OpenHabian_CLI_05.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7a6053da9f6ad25ae34e466566ee6b96/81c8e/OpenHabian_CLI_05.png 230w", "/en/static/7a6053da9f6ad25ae34e466566ee6b96/08a84/OpenHabian_CLI_05.png 460w", "/en/static/7a6053da9f6ad25ae34e466566ee6b96/e9beb/OpenHabian_CLI_05.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7a6053da9f6ad25ae34e466566ee6b96/e9beb/OpenHabian_CLI_05.png",
              "alt": "openHABian Home Automation",
              "title": "openHABian Home Automation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Choose a User Authentication for the MQTT Server (make sure to write down the password choose here - it has to be set in our `}<a parentName="p" {...{
        "href": "/en/Advanced_User/OpenHAB_Home_Automation/#connect-the-mqtt-server-to-openhab2"
      }}>{`OpenHAB MQTT Configuration`}</a>{` (inside `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`mqtt.cfg`}</code>{`) as well as in `}<a parentName="p" {...{
        "href": "/en/Advanced_User/OpenHAB_Home_Automation/#connect-the-mqtt-server-to-openhab2"
      }}>{`Node-RED`}</a>{` (inside all your `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`MQTT-Nodes`}</code>{` in every Node-RED flow: `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Node Property > MQTT Server > Security`}</code>{`).) - see `}<a parentName="p" {...{
        "href": "#troubleshooting"
      }}>{`Troubleshooting`}</a>{` below.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/571b43531a81b0edbe2f5856e00a9d97/e9beb/OpenHabian_CLI_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "39.130434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7EAAAOxAGVKw4bAAABu0lEQVQY00WLz2/SYABA+88pzOM8mHjw6P9ghM1Y1KgTDLrNuuJ0aBbXrintVyiU0X7QlrasfP0xKLSFocYletlW7saUmJi8w8tLHtYbKXKgKeOenKIqE1X2oD7saY5quJrl64oLwUCsnzYbtgQskTMbzVPxwJTvuxfYd2VnCithlzzXP8bdSiC/jyG5UD8EJ0QMyQhWRtKuyb4at7e/aWSkECEkEu+ww2/fPXGxQHpj1N/a0junTZzJe31QHkEy0j8FXXJmHMT9lKm6v7Cq5+bnuVGdW18ux8ciINK5bzEAdVTjiNdrgqMAu91w5aanNNxOy4eipzQ9RfRk0ZVbPky7I8OJVqV21zkNWwyqU4eZo68zRM1cZu4xMaIjREWIjp1UYkTPnJRVpEJE/Ryxx1T5tmBiydnh5ZS9Ctkk5pYx/8unryNuufJlzCdR7SpkL5yj3z6dRFwScdcR9+eHKNX37kgI82rPB3zR5otDUEJCyWRfIuE1ElJ3VjIExT7zwmK30g5KNigFrfL+Tn4dGFh289mNB49u5vBMDl/beHJr82kmX/hPrpDJ42sbhWzq+L/48HEW37qnTf4CcG5Tq5cNB6UAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/571b43531a81b0edbe2f5856e00a9d97/e4706/OpenHabian_CLI_06.avif 230w", "/en/static/571b43531a81b0edbe2f5856e00a9d97/d1af7/OpenHabian_CLI_06.avif 460w", "/en/static/571b43531a81b0edbe2f5856e00a9d97/b6582/OpenHabian_CLI_06.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/571b43531a81b0edbe2f5856e00a9d97/a0b58/OpenHabian_CLI_06.webp 230w", "/en/static/571b43531a81b0edbe2f5856e00a9d97/bc10c/OpenHabian_CLI_06.webp 460w", "/en/static/571b43531a81b0edbe2f5856e00a9d97/87ca7/OpenHabian_CLI_06.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/571b43531a81b0edbe2f5856e00a9d97/81c8e/OpenHabian_CLI_06.png 230w", "/en/static/571b43531a81b0edbe2f5856e00a9d97/08a84/OpenHabian_CLI_06.png 460w", "/en/static/571b43531a81b0edbe2f5856e00a9d97/e9beb/OpenHabian_CLI_06.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/571b43531a81b0edbe2f5856e00a9d97/e9beb/OpenHabian_CLI_06.png",
              "alt": "openHABian Home Automation",
              "title": "openHABian Home Automation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`And you are done!`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/5abecaa1f93232e3a5ad62bee93a3fbe/e9beb/OpenHabian_CLI_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7DAAAOwwHHb6hkAAABz0lEQVQY0zXLz2/SYBzH8f5xurqb5x3UaDzsYqLGePBgjAo4qaMIURm0wFjp82CcrJs7sRZZN2C06/M8peVH2ZJNfmzjV8niYSdTEpNXvnkfPl9qWT/9qBufEPGuQcKWzSASrpGIZkaOLfYIs7rJGCRoEMbboBXNYPTjV+Rk2bqiXtTsdjHW2Y+fVVKnKtc9Sl/h7KWWGeobEwSmCIyROMJghMUxASMMhyT31xSiP1KPrLH3fLDNKpurpa3w4a9IZTeqbn8u70b1wjdjb61VTmM5ThTOVlOd6ka7nGlWhCkBYZB4WB9Rz6r1ejHeVJN2iW/s81aJrxcTTTWJ5DX7N9dQk2Yx3j5In+vZHoJdA5wbcIyED6noUu0P9bRinmmZAYF9khuQ3MVcH0OvG9+9wLCPQM8Qu4bYQ6CL4VBf9/ORJa1LPa9aAwwnrfyklZ850vXJjutIbkeaWfnrAnBbW64jTduS60izObezc+P8ZEHiAb6knhyaBeAvCO/2su9l4FdgQIEBGfplwad8fSOLvv/8HuCTYaCUef0y+PYeuqDul53FL8KtYGwhlKBZbjHM32F5muU8kSTNcgshjl5N0KF5hHia5W8zsbvr0mPU+wc1kFSsjmfu8QAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5abecaa1f93232e3a5ad62bee93a3fbe/e4706/OpenHabian_CLI_07.avif 230w", "/en/static/5abecaa1f93232e3a5ad62bee93a3fbe/d1af7/OpenHabian_CLI_07.avif 460w", "/en/static/5abecaa1f93232e3a5ad62bee93a3fbe/b6582/OpenHabian_CLI_07.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/5abecaa1f93232e3a5ad62bee93a3fbe/a0b58/OpenHabian_CLI_07.webp 230w", "/en/static/5abecaa1f93232e3a5ad62bee93a3fbe/bc10c/OpenHabian_CLI_07.webp 460w", "/en/static/5abecaa1f93232e3a5ad62bee93a3fbe/87ca7/OpenHabian_CLI_07.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/5abecaa1f93232e3a5ad62bee93a3fbe/81c8e/OpenHabian_CLI_07.png 230w", "/en/static/5abecaa1f93232e3a5ad62bee93a3fbe/08a84/OpenHabian_CLI_07.png 460w", "/en/static/5abecaa1f93232e3a5ad62bee93a3fbe/e9beb/OpenHabian_CLI_07.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/5abecaa1f93232e3a5ad62bee93a3fbe/e9beb/OpenHabian_CLI_07.png",
              "alt": "openHABian Home Automation",
              "title": "openHABian Home Automation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h3 {...{
      "id": "troubleshooting",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#troubleshooting",
        "aria-label": "troubleshooting permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Troubleshooting`}</h3>
    <p>{`You set up everything but sending a message over MQTT from your Node-RED Dashboard or the OpenHAB Basic-UI does not do anything!`}</p>
    <p>{`We used the OpenHAB CLI to add a MQTT Authentication to the Mosquitto Server - the default user is `}<strong parentName="p">{`openhabian`}</strong>{` and the password you have chosen yourself (e.g. `}<strong parentName="p">{`mymqttpassword`}</strong>{`, s. below). That information has to be added to `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`/etc/openhab2/services/mqtt.cfg`}</code>{` to enable OpenHAB to send messages through your MQTT server:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c0d991f665c2f72ee15cabaffc644ef3/e9beb/OpenHabian_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "25.65217391304348%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAA/UlEQVQY0yXL226CMBgAYF5mm4m0yN+CgOXQc7Ei6szEaTJ1bDd7//uFLfluv6A0Mhe8FMBNJByINm50lNfUiXoU/FOrUfFR8QfLC0BAMSEohvBfsDlsjTd5tVSOc1PTHNzGSMuZrFa6zgRjuoozgigGgumUMRAEgICg4GLEoS4M4K99d+/anmU/w3Hc+atTN2/PWjy27d27dysrlk2NoL+PY0DBW73q2VLg+b3zt27ds+L7dT+oZlDN1YojLz+8PVt9aU1TZpBECM3INMMwmgdiY3lr0orsTu16LwuZ9Ke19KpySu66wqrMaOslVyyk8BzOnuYvaBGm6YIm8S+fPkqyYIgRBAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c0d991f665c2f72ee15cabaffc644ef3/e4706/OpenHabian_01.avif 230w", "/en/static/c0d991f665c2f72ee15cabaffc644ef3/d1af7/OpenHabian_01.avif 460w", "/en/static/c0d991f665c2f72ee15cabaffc644ef3/b6582/OpenHabian_01.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c0d991f665c2f72ee15cabaffc644ef3/a0b58/OpenHabian_01.webp 230w", "/en/static/c0d991f665c2f72ee15cabaffc644ef3/bc10c/OpenHabian_01.webp 460w", "/en/static/c0d991f665c2f72ee15cabaffc644ef3/87ca7/OpenHabian_01.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c0d991f665c2f72ee15cabaffc644ef3/81c8e/OpenHabian_01.png 230w", "/en/static/c0d991f665c2f72ee15cabaffc644ef3/08a84/OpenHabian_01.png 460w", "/en/static/c0d991f665c2f72ee15cabaffc644ef3/e9beb/OpenHabian_01.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c0d991f665c2f72ee15cabaffc644ef3/e9beb/OpenHabian_01.png",
              "alt": "openHABian Home Automation",
              "title": "openHABian Home Automation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`You can edit the file by using `}<strong parentName="p">{`Vim`}</strong>{` by typing `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`sudo vim "/etc/openhab2/services/mqtt.cfg"`}</code>{`. Press the letter `}<strong parentName="p">{`i`}</strong>{` to edit the file, add your `}<em parentName="p">{`mosquitto.user`}</em>{` and `}<em parentName="p">{`mosquitto.pwd`}</em>{`, then press ESC and type `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`:wq!`}</code>{` to save and close the file:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "text"
    }}><pre parentName="div" {...{
        "className": "language-text"
      }}><code parentName="pre" {...{
          "className": "language-text"
        }}>{`mosquitto.url=tcp://localhost:1883
mosquitto.clientId=openhab2
mosquitto.user=openhabian
mosquitto.pwd=mymqttpassword
mosquitto.qos=1
mosquitto.retain=false
mosquitto.async=true`}</code></pre></div>
    <p>{`To add the Authentication to Node-RED double-click your MQTT Nodes and edit the MQTT Server information:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/61c797bd5fa893ba5a0269525296f163/e9beb/OpenHabian_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7EAAAOxAGVKw4bAAABgklEQVQY01XPXY/SQBQG4P5bf4M/xL1xTXYlBt0PExORFogw00I7ZVm5URwCRC5QkY9Oz0xrOx8dQ3eN8bk4d+97znFGo1G/3w+CYFRDCE2nU/qVzmYzSul6vQaA9H8sSZJfu+P+4ERR5Ps+xhihQRiGhJD5fM5rQggAYP88hvfft4MnTz9fXjthFAWBjzBGGEdRRAihlG632+VyNad0s9kIITgHzk8HAGOpLFlIdu9aB8YcD+Fm23vd7tx0Pg7DmJDxYrHIsixN0yzPUxDAMwZcZL9LqctSldbyyVTe3UtrneEw7PZartd0vTfd7hXC3nK1AoA8z621u/1x8+Pn4chYyqUypdRFobJSGWuVlE4cj33c7rx/4V6fubfP3NsL+mV2TBIhhLX24dGqqpSSRVForbXRWqnqNJQzjuP4/hO5ett7/tI9f9U7O1/1B1IbJaW1tqoppQAgSZiuSSmNMY/h8WRy96HlNy6HzSZpNL4RkgBwzq21QmSnZcbYmvnrofEP9zSkkbYHGrEAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/61c797bd5fa893ba5a0269525296f163/e4706/OpenHabian_02.avif 230w", "/en/static/61c797bd5fa893ba5a0269525296f163/d1af7/OpenHabian_02.avif 460w", "/en/static/61c797bd5fa893ba5a0269525296f163/b6582/OpenHabian_02.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/61c797bd5fa893ba5a0269525296f163/a0b58/OpenHabian_02.webp 230w", "/en/static/61c797bd5fa893ba5a0269525296f163/bc10c/OpenHabian_02.webp 460w", "/en/static/61c797bd5fa893ba5a0269525296f163/87ca7/OpenHabian_02.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/61c797bd5fa893ba5a0269525296f163/81c8e/OpenHabian_02.png 230w", "/en/static/61c797bd5fa893ba5a0269525296f163/08a84/OpenHabian_02.png 460w", "/en/static/61c797bd5fa893ba5a0269525296f163/e9beb/OpenHabian_02.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/61c797bd5fa893ba5a0269525296f163/e9beb/OpenHabian_02.png",
              "alt": "openHABian Home Automation",
              "title": "openHABian Home Automation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/661e75b49094229b1b46167f8ea13b3e/e9beb/OpenHabian_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "60.434782608695656%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAIAAADtbgqsAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAB0ElEQVQoz32S64oTMRiGe73egZfgBXgH/hEWUdG//hBE3N1ulW6LtevszHQy7UwOzWEm+TI5yUzFWgQfXvK9BJ6QQGb7uq4R2u/HuVwuVxObzWa73UopxSWnHQWms5YrOSMYN03DGKOUtoS0BLd4DCaEXyKEIIRwIW6fPf/45Gl5s5gppcBaADDGDACDAdDaag1gtPkr09BaG4Dqw6fs6j3NdzMpJSHYpxQZQy9eFq/eVq/fVVdv1PVdSMn76EPyIYX4O27sMaUEADNtDKMME3I8HjvGFeOScZQX7aGVYIUBoUEYaBjHQopeC9kpqawx1tqZMfrI2P5waLPMl2XYVZA97ud3zeIbX635an28X/HVGl3foM9fJKpU3wnO9UmWUjDGXIpx+0DuVzzLIya+xb7Frmld00Bd9+Wu21Xi+w+/fUgpxfO1taaEYkpxlh1u5/v5oll8HR5zX+xcXrq88EU59WL4mUUhL+Su68bXKjWEYJzrre2tHWIcUgopxWk9lbHHGEI4y0opjHHfdcF7JaWSUve94FxwDgDeOe/8FOedC95fyH3fSaXE9AeUUikl5xyllDFGKHXOpX84y0LKGqGq2iFUY0xQjcaz0//4I/8C53qi/jwE7kIAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/661e75b49094229b1b46167f8ea13b3e/e4706/OpenHabian_03.avif 230w", "/en/static/661e75b49094229b1b46167f8ea13b3e/d1af7/OpenHabian_03.avif 460w", "/en/static/661e75b49094229b1b46167f8ea13b3e/b6582/OpenHabian_03.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/661e75b49094229b1b46167f8ea13b3e/a0b58/OpenHabian_03.webp 230w", "/en/static/661e75b49094229b1b46167f8ea13b3e/bc10c/OpenHabian_03.webp 460w", "/en/static/661e75b49094229b1b46167f8ea13b3e/87ca7/OpenHabian_03.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/661e75b49094229b1b46167f8ea13b3e/81c8e/OpenHabian_03.png 230w", "/en/static/661e75b49094229b1b46167f8ea13b3e/08a84/OpenHabian_03.png 460w", "/en/static/661e75b49094229b1b46167f8ea13b3e/e9beb/OpenHabian_03.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/661e75b49094229b1b46167f8ea13b3e/e9beb/OpenHabian_03.png",
              "alt": "openHABian Home Automation",
              "title": "openHABian Home Automation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/dfebd54b7fc880eed001ebb7339fa7e4/e9beb/OpenHabian_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "43.913043478260875%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABPUlEQVQoz2WQ0U7DMAxF+7O88AG88GtISCDxA93G9oQmlqbJVidxknZJPQd11cYG58mWfO17Xe12OyEaKeVms14uFnVdf67Xy9VKSum9t9a6W6x1iNhH3/dK62qv9wYMAHRdd7jBGGP/YAw4q7+2rw9Pb4/P23pV5ZxTSsd/DMNwW0/tMBxT6tF/v7yL1w8tmqpVrQ+hlIKIQggppVIa0ZdSTheYuZTCzHNbzjjnKnSubVtrbQjBex9CQHRCCAAgopwzEXlEKSUAzDMIJvXDJDbGCNEAACLOT/Hea6WstdMtosLs3LRufzhcZ1JKk7jrwBjDzDEGKWWMcXbFpRAXKoWIZqvnVXRnGyY6Zg4hKKVijGMHqZGpVVnprPR4PI4X5hS/4jkGM+ec+74fx/EUAhlL1pFDcngax9M9V/EPaPD930mv6LYAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dfebd54b7fc880eed001ebb7339fa7e4/e4706/OpenHabian_04.avif 230w", "/en/static/dfebd54b7fc880eed001ebb7339fa7e4/d1af7/OpenHabian_04.avif 460w", "/en/static/dfebd54b7fc880eed001ebb7339fa7e4/b6582/OpenHabian_04.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/dfebd54b7fc880eed001ebb7339fa7e4/a0b58/OpenHabian_04.webp 230w", "/en/static/dfebd54b7fc880eed001ebb7339fa7e4/bc10c/OpenHabian_04.webp 460w", "/en/static/dfebd54b7fc880eed001ebb7339fa7e4/87ca7/OpenHabian_04.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dfebd54b7fc880eed001ebb7339fa7e4/81c8e/OpenHabian_04.png 230w", "/en/static/dfebd54b7fc880eed001ebb7339fa7e4/08a84/OpenHabian_04.png 460w", "/en/static/dfebd54b7fc880eed001ebb7339fa7e4/e9beb/OpenHabian_04.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/dfebd54b7fc880eed001ebb7339fa7e4/e9beb/OpenHabian_04.png",
              "alt": "openHABian Home Automation",
              "title": "openHABian Home Automation",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "manual-installation-optional",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#manual-installation-optional",
        "aria-label": "manual installation optional permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Manual Installation (optional)`}</h2>
    <p>{`If you are not using the openHABian Linux distro on your Pi, you can install or upgrade Mosquitto following the regular `}<a parentName="p" {...{
        "href": "https://mosquitto.org/blog/2013/01/mosquitto-debian-repository/"
      }}>{`Setup Guide`}</a>{`.`}</p>
    <p>{`To use the new repository, you should first import the repository package signing key:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`wget`}</span>{` http://repo.mosquitto.org/debian/mosquitto-repo.gpg.key
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` apt-key `}<span parentName="code" {...{
            "className": "token function"
          }}>{`add`}</span>{` mosquitto-repo.gpg.key`}</code></pre></div>
    <p>{`Then make the repository available to apt:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token builtin class-name"
          }}>{`cd`}</span>{` /etc/apt/sources.list.d/`}</code></pre></div>
    <p>{`Then one of the following, depending on which version of debian you are using:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`wget`}</span>{` http://repo.mosquitto.org/debian/mosquitto-wheezy.list
`}<span parentName="code" {...{
            "className": "token function"
          }}>{`sudo`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`wget`}</span>{` http://repo.mosquitto.org/debian/mosquitto-jessie.list`}</code></pre></div>
    <p>{`Then update apt information:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`apt-get`}</span>{` update`}</code></pre></div>
    <p>{`And install the package:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "bash"
    }}><pre parentName="div" {...{
        "className": "language-bash"
      }}><code parentName="pre" {...{
          "className": "language-bash"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`apt-get`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`install`}</span>{` mosquitto`}</code></pre></div>
    <p>{`Alternative Mosquitto Downloads can be found `}<a parentName="p" {...{
        "href": "https://mosquitto.org/download/"
      }}>{`here`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      